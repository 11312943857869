@mixin accord(){
    &__item{
        &__header{
            font-family: $ff-medium;
            font-size: 1em;
            color: $color-gray-5;

            margin: 0;
            padding: .7em 1em;

            border-radius: 3px;
            border: 1px solid $color-gray-1;
            
            &[data-status='opened']{
                border: none;
                border-radius: 0;
                border-bottom: 2px solid $color-gray-1;
                color: $color-second;
            }
            &[data-status='opened']::before {
                content: '/f03e'
            }

            @include hover-focus {
                border-color: $color-gray-2;
                cursor: pointer;

                &[data-status='close']::before {
                    color: $color-second;
                }
            }
            &:active {
                background-color: transparent;
                cursor: pointer;
            }
        }
    }
}
.accordion{
    @include accord();
    flex-direction: column;
    &__item{
            justify-content: center;
        &__header{
            width: 100%;

            position: relative;
            display: flex;
            flex-direction: row;
            align-content: stretch;
            align-items: stretch;

            &::before{
                position: absolute;
                margin-top: -0.5em;
                top: 50%;
                right: 1em;
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                color: $color-gray-4;
            }
            &[data-status = 'opened']::before{
                content: "\f106";
            }
            &[data-status = 'close']::before{
                content: '\f107';
            }
        }
        &__body{
            padding-left: 1.125em;
            padding-top: $grid-gutter/2;
            padding-bottom: $grid-gutter/2;
        }
    }
}