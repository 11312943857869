.header-hightlight{
    border-left: 6px solid $color-second;
    padding-left: 2.625rem;
}
.dash-line{
    border-top: 1px dashed #707070;
}

.plate-separator{
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}
.section-separator {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.gray-section{
    background-color: $color-bg;
    border-top: 1px solid $color-gray-1;
}

.gray-section-separator {
    @extend .gray-section;
    padding-top: 3rem;
    padding-bottom: 3rem;
    margin-top: 2rem;
}

.row-separator {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.gray-plate {
    background-color: $color-bg;
    border-radius: 10px;
    border: 1px solid #F3F3F3;
    @extend .plate-separator;
}

%border-top{
    border-top: none;
}
%border-bottom{
    border-bottom: none;
}
.border-top{
    border-top: none;
}

.h1-section-separator{
    // @extend %border-bottom;
    padding-bottom: 1rem;
}
.article-separator{
    @extend %border-top;
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.content-separator{
    height: 1px;
    width: 100%;
    background-color: $color-gray-2;
    margin-top: 0.8em;
    margin-bottom: 1.2em;
}
.gray-section-decor{
    background-color: $color-bg;
    border-top: 1px solid $color-gray-1;
    @extend %border-bottom;
    padding-top: 20px;
    padding-bottom: 10px;
}
.section-decor{
    padding-top: 20px;
    padding-bottom: 30px;
}

.section-decor-line{
    width: 73.5%;
    height: 2px;
    background-color: $color-gray-3;
    
    position: absolute;
    top: 0;
    left: $grid-gutter/2;
}

.news-section {
    background-color: $color-gray-1;
}
.sections-section{
    padding-top: 1.5em;
    padding-bottom: 2em;
}

.desc-section{
    background-color: $color-bg;
}