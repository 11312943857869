.header{
    height: 104px;
    background-color: #fff;

    &__logo{
        padding-bottom: 1px;
        img{            
            width: auto;
            height: 28px !important;

            @include media-breakpoint-up(md){
                height: 32px !important;
            }
            
            @include media-breakpoint-up(xl){
                height: 50px !important;
            }
        }
        a{
            font-family: $ff-h-bold;
            color: $color-prime;
            text-decoration: none;
        }
    }
    &__phone{
        margin-top: -0.3rem;
        @include media-breakpoint-up(xl){
            margin-top: -0.7rem;
        }
    }
    &__phone-link{
        color: $color-gray-6;
        text-decoration: none;
        font-size: 1.325rem;
        line-height: 1em;
        font-family: $ff-h-bold;

        @include hover-focus-active {
            color: $color-gray-4;
            text-decoration: underline;
        }
        @include media-breakpoint-up(xl){
            font-size: 1.625rem;
        }
    }
    &__email-link{
        font-family: $ff-h-bold;
        margin-bottom: 0.2rem;
        @include media-breakpoint-up(xl){
            margin-bottom: 0.4rem;
        }
    }
    &__menu{
        background-color: $color-prime;
    }
    &__search-icon,
    &__search-icon-close,
    &__mob-menu-btn {
        padding-left: 30px;
        padding-right: 30px;
        color: $color-prime;
        @include hover-focus-active{
            background-color: $color-second;
            cursor: pointer;
        }
        &__text{
            font-family: $ff-h-bold;
        }
    }
}

.header-mobile{
    height: 68px;
    background-color: #fff;
    border-bottom: 6px solid $color-prime;

    font-size: 14px;

    @include media-breakpoint-up(md){
        height: 78px;
        border-bottom: 8px solid $color-prime;
    }
}

.header-mobile.sticky{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 800;
}
.header-mobile.sticky + main, .header-mobile.sticky + .instead-slider{
    padding-top: 68px;
}