.teaser{
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: $color-gray-1;

    &__text{
        padding-left: 1.5em;
        padding-right: 1.5em;
    }
    &__title{
        @extend .h2;
        margin-bottom: .2em;
    }
    &__desc{
        font-family: $Roboto;
        line-height: 1.4em;
    }
    &__phone{
        font-size: 1.7rem;
        line-height: 1.6em;
    }
    &__phone-box, &__btn{
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    &__phone-box, &__btn{
        @include media-breakpoint-up(xs){
            justify-content: left;
        }
        @include media-breakpoint-up(sm){
            justify-content: center;
        }
    }
    &__culc{
        margin-top: 1rem;
        padding-left: 2rem;
        border-left: 4px solid $color-gray-2;
    }

}
