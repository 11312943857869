.footer-item{
    padding: .3em 0;
    line-height: 1.4em;
    font-family: $ff-normal;
    text-align: left;
}
.footer{
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
    background-color: $color-prime;
    color: $color-gray-light;

    &__logo{
        height: 2rem;
        margin-bottom: 2rem;
    }

    @include media-breakpoint-up(xl){
        font-size: 16px;
    }
    a{
        color: $color-gray-light;
    }
    &__link{
        font-family: $ff-normal;
        text-decoration: none;
        @include hover-focus-active{
            text-decoration: underline;
        }
    }
    &__caption{
        a{
            font-family: $ff-h-bold;
            font-size: 1.25em;
            line-height: 1.4em;
            padding-top: .5em;
            padding-bottom: 1.25em;
            text-decoration: none;
            @include hover-focus-active{
                text-decoration: underline;
            }
        }
    }

    .menu{
        $root: &;
        &__item{
            @include hover-focus-active {
                .menu__link {
                    background-color: transparent;
                }
            }
        }
        &__link{
            @extend .footer-item;
            @include hover-focus-active{
                background-color: transparent;
                text-decoration: underline;
            }
        }
    }

    &__hr{
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        height: 2px;
        background-color: $color-gray-4;
    }

    &__icon{
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        padding: .3em .4em;

        font-size: 1.4em;

        border: 1px solid #fff;
        border-radius: 5px;
        @include hover-focus-active{
            background-color: rgba($color: #fff, $alpha: 0.4);
        }
        @include media-breakpoint-up (lg){
            font-size: 1.6em;
        }
    }
    &__info{
        @include media-breakpoint-only(xs){
            padding-bottom: 1em;
            > div{
                text-align: center;
                justify-content: center;
            }
        }
    }
    &__author{
        padding-top: .3em;
    }


}