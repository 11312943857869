.contacts {
    // background: linear-gradient(180deg, #ffffff 50%, $color-prime-bg-light 50%);
    // &__wrapper{
    //     @extend .cover;
    // }
    &__info {
        padding-top: 40px;
        padding-bottom: 40px;
        background-color: #fff;
        >:not(:first-child) {
            margin-top: 20px;
        }
    }
    &__form-box {
        padding-top: 10px;
        padding-bottom: 40px;
        @include media-breakpoint-up(lg) {
            padding-top: 40px;
        }
        // >:not(:first-child) {
        //     margin-top: 20px;
        // }
    }
    @include media-breakpoint-up(lg) {
        background: linear-gradient(90deg, #ffffff 50%, $color-prime-bg-light 50%);
    }
}

// .contacts_info > div:not(:first-child){
//     margin-top: 20px;
// }