.section-card {
    @include card;

    &__wrapper{
        margin-bottom: 2rem;
    }

    &__img {
        overflow: hidden;
        // height: 230px;
    }

    &__name {
        padding-top: 1.125em;
        padding-bottom: 1em;

        a {
            font-family: $ff-h-bold;
            font-size: 1.125rem;
            padding-top: 1px;
        }
    }
}

.main-page__service {
    .section-card{
        border-bottom-color: transparent;

        &__wrapper{
            margin-bottom: 1rem;
        }

        &__name{
            a{
                color: #fff;
                font-family: $ff-h-bold;
            }
        }
        @include hover-focus-active{
            border-bottom-color: rgba($color: #fff, $alpha: 0.4);
            .section-card__name{
                background-color: rgba($color: #fff, $alpha: 0.15);
                a {
                    color: #fff;
                }
            }
        }
    }
}