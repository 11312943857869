.tabs{
    padding-bottom: 1rem;
    &__nav{
        border-top: 2px solid $color-gray-1;
        border-bottom: 2px solid $color-gray-1;

        &__item{
            padding-top: 1.08rem;
            padding-bottom: 1rem;
            padding-left: 1.5rem;
            padding-right: 1.5rem;

            font-family: $headings-font-family;
            color: $color-gray-4;

            @include hover-focus-active{
                cursor: pointer;
                background-color: $color-gray-1;
            }

            &.active{
                color: $color-gray-6;
                cursor: default;
                background-color: transparent;
            }
        }
    }
    &__tab{
        display: none;

        padding-top: 1rem;
        padding-bottom: 1.5rem;
        
        &.active{
            display: flex;
        }
    }
}