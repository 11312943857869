.product-card-list {
    @include card;

    border-bottom: none;

    &__name{
        text-transform: uppercase;
    }

    // &__img{
    //     width: 50%!important;
    // }
}

