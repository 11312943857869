.product-card {
    @include card;
    &__wrapper{
        @include hover-focus-active {
            @extend %hover-effect-shadow;
        }
        margin-bottom: 2.5rem;
    }
    @include hover-focus-active {
        box-shadow: none;
    }
}
