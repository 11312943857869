//
// Modals
// --------------------------------------------------
// Kill the scroll on the body
.modal-open {
    overflow: hidden;
}

// Container that the modal scrolls within
.modal {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 5;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100%;
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-modal;
    -webkit-overflow-scrolling: touch;
    background-color: rgba(0, 0, 0, 0.5);

    outline: 0;

    // When fading in the modal, animate it to slide down
    // &.fade .modal-dialog {
    //     .translate3d(0, -25%, 0);
    //     .transition-transform(~"0.3s ease-out");
    // }

    // &.in .modal-dialog {
    //     .translate3d(0, 0, 0)
    // }
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.form-modal {
    .modal-content {
        width: 315px;

        @include media-breakpoint-up(sm) {
            width: 410px;
        }

        // @include media-breakpoint-up(md){
        //     width: 680px;
        // }
    }
}

// .modal_order {
//     display: flex;
// }

// .modal_call {
//     display: flex;
// }

.modal_drop-menu {

    // display: flex;
    .modal-content {
        padding-top: 0;
        padding-bottom: 20px;
        padding-left: 30px;
        padding-right: 30px;
    }

    .modal-header {
        border-bottom: 2px solid #eee;
        margin-bottom: 20px;

        &__title {
            margin-top: 0.6em;
            text-align: left;
        }
    }
}


.modal-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 25px;
    // width: 580px;
    position: relative;
    background-color: $modal-content-bg;
    border: 1px solid $modal-content-fallback-border-color; //old browsers fallback (ie8 etc)
    border: 1px solid $modal-content-border-color;
    border-radius: 10px;
    background-clip: padding-box;

    outline: 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .5);

    @include media-breakpoint-only(sm) {
        width: 520px;
    }
}

// Modal background
.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-modal-background;
    background-color: $modal-backdrop-bg;

    // Fade for backdrop
    // &.fade {
    //     .opacity(0)
    // }

    // &.in {
    //     .opacity($modal-backdrop-opacity);
    // }
}

// Modal header

.modal-header {
    text-align: center;

    // align-items: center;
    // justify-content: center;
    &__title {
        text-align: center;
        margin-bottom: 15px;
    }

    &__sub-title {
        text-align: center;

        strong {
            font-family: $ff-bold;
            font-size: 1.125em;
        }
    }

    &__desc {
        font-family: $ff-italic;
        text-align: left;
        padding-bottom: 10px;
    }

    // padding: $modal-title-padding;
    // margin:0 -15px;
    // position: relative;
    // border-bottom: 5px solid $modal-header-border-color;
    background-color: none;
    // &:before,&:after{
    // 	content:"";
    // 	width: 0;
    // 	height: 0;
    // 	border-style: solid;
    // 	border-width: 0 12px 75px 0;
    // 	border-color: transparent $dark transparent transparent;
    // 	position: absolute;
    // 	top: 0; left: -12px;
    // }
    // &:after{
    // 	border-width: 75px 0 0 12px;
    // 	border-color: transparent transparent transparent $dark;
    // 	left: auto;
    // 	right: -12px;
    // }
}

// Close icon
.close-modal {
    width: 52px;
    height: 52px;
    border: none;
    box-sizing: border-box;
    // border: 2px solid #00a6ae;
    // border-radius: 11px;
    background-color: transparent;
    position: absolute;
    top: 0px;
    right: 0px;

    /*font-size: 12px;
	line-height: 18px;
	color: #00a6ae;
	text-align: center;
*/
    &:active,
    &:focus {
        outline: none;
    }

    i.fa {
        display: block;
        width: inherit;
        height: inherit;
        padding: 3px 0 0 1px;
        box-sizing: border-box;
        // border: 2px solid $white;
        // border-radius: 11px;
        // margin-left: -1px;
        font-size: 1em;
        color: $font-color-base;
        text-align: center;

        // line-height: inherit;
        &:hover {
            color: lighten($font-color-base, 30%);
        }
    }
}

.modal-body {
    // padding: $modal-inner-padding;
    padding-bottom: 20px;
    position: relative;
}

// Footer (for actions)
.modal-footer {
    padding: $modal-inner-padding;
    text-align: right; // right align buttons
    border-top: 1px solid $modal-footer-border-color;

    // Properly space out buttons
    .btn+.btn {
        margin-left: 5px;
        margin-bottom: 0; // account for input[type="submit"] which gets the bottom margin like all other inputs
    }

    // but override that for button groups
    .btn-group .btn+.btn {
        margin-left: -1px;
    }

    // and override it for block buttons as well
    .btn-block+.btn-block {
        margin-left: 0;
    }
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}
