//
// Table
//
$table-header-font-family:          $headings-font-family;
$table-header-border-top:           1px solid $color-gray-2;
$table-header-border-bottom:        2px solid $color-gray-2;

$table-item-border :                1px solid $color-gray-2;
$table-item-padding :               1rem 1rem;


.table, table{
	padding-bottom: 1rem;
	padding-top: 1rem;
	border: none;
	margin-top: 5px;
	border-collapse: collapse;
	margin-bottom: 1rem;

	&__caption, caption{
		font-size: 1.25em;
		font-family: $table-header-font-family;
		text-align: left;
		justify-content: flex-start;
		color: $font-color-base;
		padding: 1rem;
	}
	&__header, thead tr{
		font-family: $table-header-font-family;
		border-top: $table-header-border-top;
		border-bottom: $table-header-border-bottom;
		div, td{
			// font-size: 1.125em;
			border: none;
			line-height: 1.6em;
			color: $font-color-base;
			padding: $table-item-padding;
			justify-content: center;
			align-items: center;
			&:first-child{
				// padding-left: $grid-gutter/2;
				justify-content: flex-start;
			}
			&:last-child{
				// padding-right: $grid-gutter/2;
			}
		}
	}
	&__item, tbody tr{
		// font-family: $OpenSansSemiBold;
		font-size: 1em;
		border-top: $table-item-border;
		align-items: center;
		align-content: center;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		&:last-child{
			border-bottom: $table-item-border;
		};
		&:nth-of-type(even){
			background-color: transparentize($color-gray-1, 0.5);
		};
		&:hover{
			background-color: $color-gray-1;
		}
		div, td{
			border: none;
			line-height: inherit;
			color: $font-color-base;
			padding: $table-item-padding;
			align-content: center;
			align-items: center;
			justify-content: center;
			&:first-child{
				// padding-left: $grid-gutter/2;
				justify-content: flex-start;
			}
			&:last-child{
				// padding-right: $grid-gutter/2;
			}
			.fa{
				font-size: 0.9em;
				margin-top: 1px;
			}
			span{
				margin-top: 1px;
			}
		}
	}

	&_striped{
		.table__item:nth-of-type(even){
			background-color: transparentize($color-gray-1, 0.7);
		}
		tbody tr:nth-of-type(even){
			background-color: transparentize($color-gray-1, 0.7);
		}
	}
	&_hovered{
		.table__item{
			&:hover{
				background-color: transparentize($color-gray-1, 0.4);
			}
		}
	}

}












// // ====================================
// // Table-div
// .table-div{
// 	padding: 45px 95px 30px;
// 	margin-bottom: 62px;

// 	h3.h3{
// 		margin-bottom: 40px;
// 	}
// }

// 	.table-div__sub-header{
// 		margin: 20px 0 5px;
// 		// margin: 30px 0 10px;
// 		font: 16px $PTSansBold;
// 		color:$color-primary-0-0;
// 	}

// 	.table-div__row{
// 		display: table;
// 		width: 100%;
// 		padding: 3px 0;
// 		// padding: 10px 0;
// 	}

// 		.table-div__tc{
// 			display: table-cell;
// 			font: 14px $OpenSansBold;

// 			&:first-child{
// 				width: 70%;
// 				font-family: $OpenSansReg;
// 			}
// 		}


// // ====================================
// // Tables

// // Baseline styles

// .table {

// 	width: 100%;
// 	margin-bottom: 62px;
// 	background-color: $table-bg;

// 	// margin-bottom: $line-height-computed;
// 	// Cells
// 	thead,
// 	tbody,
// 	tfoot {
// 		> tr {
// 			> th,
// 			> td {
// 				padding: $table-cell-padding;
// 				border-top: 1px solid $table-border-color;

// 				font: $table-font-size/$table-line-height $table-font-family;
// 				vertical-align: middle;
// 				text-align: center;
// 			}

// 			&.table__divider{
// 				>td{
// 					background-color: $color-primary-0-0;
// 					font-family: $table-header-font-family;
// 					text-align: left;
// 					color:$white;
// 				}
// 			}
// 		}
// 	}
// 	// Bottom align for column headings
// 	thead > tr > th {

// 		border-bottom: 2px solid $table-border-color;
// 		font-family: $table-header-font-family;
// 		vertical-align: middle;
// 	}

// 	// Remove top border from thead by default
// 	caption + thead,
// 	colgroup + thead,
// 	thead:first-child {
// 		tr:first-child {
// 			th, td {
// 				border-top: 0;
// 			}
// 		}
// 	}

// 	// Account for multiple tbody instances
// 	tbody + tbody {
// 		border-top: 2px solid $table-border-color;
// 	}
// }


// // Condensed table w/ half padding

// .table-condensed {
// 	thead,
// 	tbody,
// 	tfoot {
// 		> tr {
// 			> th,
// 			> td {
// 				padding: $table-condensed-cell-padding;
// 			}
// 		}
// 	}
// }


// // Bordered version
// //
// // Add borders all around the table and between all the columns.

// .table-bordered {

// 	border: 1px solid $table-border-color;

// 	> thead,
// 	> tbody,
// 	> tfoot {
// 		> tr {
// 			> th,
// 			> td {
// 				border: 1px solid $table-border-color;
// 			}
// 		}
// 	}
// 	> thead {
// 		> tr {
// 			> th,
// 			> td {
// 				border-bottom-width: 2px;
// 			}
// 		}
// 	}
// }

// .table-inner-bordered {

// 	> thead,
// 	> tbody,
// 	> tfoot {
// 		> tr {
// 			> th,
// 			> td {
// 				border: 1px solid $table-border-color;
// 				&:first-child{
// 					border-left: none;
// 				}
// 				&:last-child{
// 					border-right: none;
// 				}
// 			}

// 			&:last-child{
// 				>th,
// 				>td {
// 					border-bottom: none;
// 				}
// 			}
// 		}
// 	}
// 	> thead {
// 		> tr {
// 			> th,
// 			> td {
// 				border-width:0 0 2px;
// 			}
// 		}
// 	}
// }


// // Zebra-striping
// //
// // Default zebra-stripe styles (alternating gray and transparent backgrounds)

// .table-striped {

// 	> tbody {

// 		> tr:nth-child(odd) {
// 			> td,
// 			> th {
// 				background-color: $table-bg-strip;
// 			}
// 		}
// 	}
// }


// // Hover effect
// //
// // Placed here since it has to come after the potential zebra striping

// .table-hover {
// 	> tbody {
// 		> tr:hover {
// 			> td,
// 			> th {
// 				background-color: $table-row-hover;
// 			}
// 		}
// 	}
// }


// // Table cell sizing
// //
// // Reset default table behavior

// table col[class*="col-"] {
// 	float: none;
// 	display: table-column;
// }
// table {
// 	td,
// 	th {
// 		&[class*="col-"] {
// 			float: none;
// 			display: table-cell;
// 		}
// 	}
// }


// // Table backgrounds
// //
// // Exact selectors below required to override `.table-striped` and prevent
// // inheritance to nested tables.

// .table > thead > tr,
// .table > tbody > tr,
// .table > tfoot > tr {
// 	> td.active,
// 	> th.active,
// 	&.active > td,
// 	&.active > th  {
// 		background-color: $table-bg-active;
// 	}
// }

// // // Generate the contextual variants
// // .table-row-variant(success; $state-success-bg; $state-success-border);
// // .table-row-variant(danger; $state-danger-bg; $state-danger-border);
// // .table-row-variant(warning; $state-warning-bg; $state-warning-border);


// // Responsive tables
// //
// // Wrap your tables in `.table-scrollable` and we'll make them mobile friendly
// // by enabling horizontal scrolling. Only applies <768px. Everything above that
// // will display normally.

// $media (max-width: $screen-sm-max) {
// 	.table-responsive {
// 		width: 100%;
// 		margin-bottom: 15px;
// 		overflow-y: hidden;
// 		overflow-x: scroll;
// 		border: 1px solid $table-border-color;

// 		// Tighten up spacing and give a background color
// 		> .table {
// 		margin-bottom: 0;
// 		background-color: #fff;

// 		// Ensure the content doesn't wrap
// 		> thead,
// 		> tbody,
// 		> tfoot {
// 			> tr {
// 				> th,
// 				> td {
// 				white-space: nowrap;
// 				}
// 			}
// 		}
// 	}

// 		// Special overrides for the bordered tables
// 		> .table-bordered {
// 			border: 0;

// 			// Nuke the appropriate borders so that the parent can handle them
// 			> thead,
// 			> tbody,
// 			> tfoot {
// 				> tr {
// 					> th:first-child,
// 					> td:first-child {
// 						border-left: 0;
// 					}
// 					> th:last-child,
// 					> td:last-child {
// 						border-right: 0;
// 					}
// 				}
// 				> tr:last-child {
// 					> th,
// 					> td {
// 						border-bottom: 0;
// 					}
// 				}
// 			}
// 		}
// 	}
// }