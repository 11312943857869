$fontPath: "../assets/fonts/";

// Подключеные шрифты
// + Open Sans
// + Roboto
// + PT Mono

// Fira Sans Condensed  Medium
// Fira Sans  Medium
// Fira Sans Regular


@font-face {
    font-family: 'OpenSans';
    src: url($fontPath+'Open_Sans/OpenSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
	font-display: swap;
}

@font-face {
    font-family: 'OpenSansItalic';
    src: url($fontPath+'Open_Sans/OpenSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
	font-display: swap;
}

@font-face {
    font-family: 'OpenSansMedium';
    src: url($fontPath+'Open_Sans/OpenSans-Semibold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
	font-display: swap;
}

@font-face {
    font-family: 'OpenSansBold';
    src: url($fontPath+'Open_Sans/OpenSans-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
	font-display: swap;
}

@font-face {
    font-family: 'OpenSansBoldItalic';
    src: url($fontPath+'Open_Sans/OpenSans-BoldItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
	font-display: swap;
}

// Roboto

@font-face {
	font-family: 'Roboto';
	src: url($fontPath+'Roboto/Roboto-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'RobotoItalic';
	src: url($fontPath+'Roboto/Roboto-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'RobotoMedium';
	src: url($fontPath+'Roboto/Roboto-Medium.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'RobotoBold';
	src: url($fontPath+'Roboto/Roboto-Bold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'RobotoBoldItalic';
	src: url($fontPath+'Roboto/Roboto-BoldItalic.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}


// @font-face {
// 	font-family: 'PTMono';
// 	src: url($fontPath+'PT_Mono/pt_mono.ttf') format('truetype');
// 	font-weight: normal;
// 	font-style: normal;
// 	font-display: swap;
// }
