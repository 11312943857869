.catalog-header-btn{
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 4px;
    z-index: 1050;
}

.catalog__main{
    padding-left: 3rem;
    @include media-breakpoint-down(md){
        padding-left: 0;
        padding-right: 0;
        order: 1;
    }
}

// Разделы каталога

.aside{
    @include media-breakpoint-down(md){
        order: 2;
    }
    font-size: 16px;
    &__header{
        font-family: $headings-font-family;
        font-size: 1.25em;
        line-height: 1.4em;

        padding-bottom: 0.625em;
        border-bottom: 2px solid $color-second;
        margin-bottom: 0.75em;
    }
}

%catalog-menu-decor{
    position: absolute;
    content: '';
    left: 0;
    width: 100px;
    height: 1px;
    background-color: #dbe6e0;
    // background-color: #E0E0E0;
    transition: width 0.3s ease;
}

.catalog__menu {
    // font-size: 16px;

    margin-bottom: 2.5em;

    &__header{
        padding-bottom: 0.5rem;
        margin-bottom: 0.75rem;

        border-bottom: 4px solid #E6E6E6;
        img{
            height: 48px;
            margin-right: 1rem;
        }
        span{
            font-family: $ff-h-bold;
            font-size: 1.125rem;
        }
        .fa-solid, .icon{
            color: $color-second;
            font-size: 1.3rem;
            padding-right: 0.3rem;
            outline: 0.1rem;
        }
    }

    &__item {
        $root: &;

        @extend %flex;
        flex-basis: 100%;
        width: 100%;

        position: relative;
        padding: .875em 1em .875em 1em;
        margin-bottom: 0.75rem;

        font-family: $ff-h-bold;
        font-size: 1em; // font-size: 16px;
        text-decoration: none;
        color: $color-base;

        border: 2px solid transparent;

        background-color: $color-bg-3;

        @include hover-focus-active {
            // color: $color-second;
            background-color: #fff;
            border-color: $color-gray-1;
            // color: $color-gray-4;
        }
        &.active {
            color: #fff;
            background-color: $color-second;
            // border-left-color: $color-gray-2;
        }
    }
}

.cat-option{
    padding-bottom: 1rem;
    border-bottom: 2px solid #E0E0E0;
    &__view{
        &__control{
            &.catalog_tile{
                margin-right: 1rem;
            }
        }
    }
    &__sorting{
        margin-right: 2rem;
        &__title{
            font-family: $RobotoBold;
            font-size: 14px;
            color: $color-gray-4;
            margin-right: .5rem;
        }
        &__control.down{
            margin-right: 1rem;
        }
    }
}