.instead-slider{
    height: 1.25rem;
    // background-color: $color-gray-2;
}

.banner {
    // height: 350px;
    background-color: $color-clouds;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.banner {
    position: relative;
    background-position: center center;
    background-size: cover;
    &__cover{
        margin-bottom: 1em;
        padding-bottom: 4px;
        border-bottom: 5px solid $color-gray-2;
    }
    &__info {
        margin-bottom: 1rem;
        &__bg{
            padding: 1rem 2rem 2rem 2rem;
            border-top: 4px solid $color-prime;
            background-color: rgba($color: $color-gray-1, $alpha: 0.9);
        }
        &__header{
            font-size: $h1-font-size;
            margin-top: 1rem;
            margin-bottom: 0.75rem;
        }
        &__sub-header{
            font-size: 0.4em;
        }
        &__text {
            font-family: $ff-normal;
            font-size: 1.125rem;
            line-height: 1.6em;
            margin-bottom: 0.75rem;
            p{
                font-family: inherit;
                font-size: inherit;
                line-height: inherit;
            }

            h2{
                display: block;

                font-family: $ff-h-bold;
                font-size: 1.8em;
                text-align: right;
            }
        }
        &__note{
            margin-top: .5rem;
            font-family: $ff-normal;
        }
    }
}
.slider, .photos, .photos__main, .photos__nav, .gallery, .gallery__nav{
    flex-basis: auto;
}

.photo{
    margin-bottom: 1rem;
    padding-top: 1rem;
}
.photos{
    padding-bottom: 1.5rem;
    @include media-breakpoint-up(lg){
        padding-bottom: 0;
    }
    &__main, .photo{
        @extend .photo;
    }
    &__nav{
        // .slick-slide{
        //     margin-right: 1rem;
        // }
        // .slick-slide:last-child{ 
        //     margin-right: 0;
        // }
        .slide {
            padding: $grid-gutter/2;
        }

        .slick-slide.slick-active {
            border: 2px solid transparent;

            @include hover-focus-active {
                cursor: pointer;
            }
        }

        .slick-slide.slick-active.slick-current {
            border-color: $color-second-2;
        }
    }
}

.gallery{
    padding-bottom: 2rem;
    &__nav{
        margin-top: $grid-gutter;
        .slide{
            padding: $grid-gutter/2;
        }
        .slick-slide.slick-active{
            border: 2px solid transparent;
            @include hover-focus-active{
                cursor: pointer;
            }
        }
        .slick-slide.slick-active.slick-current{
            border-color: $color-second-2;
        }
    }
}

.slide{
    @include hover-focus-active{
        outline: none;
    }
}

// .slider{
// }

#run-slick-slider {
    opacity: 0;
    // display: 350px;
}

.slider-tabs{
    position: absolute;
    bottom: 0;
    z-index: 1100;
    &__tab{
        &__link{
            padding: 20px;
            box-sizing: border-box;

            align-items: flex-end;
            align-content: flex-end;

            font-family: $ff-medium;
            font-size: 16px;
            line-height: 1.4em;
            color: #333;
            text-transform: uppercase;
            text-decoration: none;

            border-bottom: 4px solid #828282;
            background-color: rgba($color: #ffffff, $alpha: 0.5);

            @include hover-focus-active {
                background-color: rgba($color: #ffffff, $alpha: 0.8);
                border-bottom-color: $color-second-2;
            }
            @include media-breakpoint-up(md){
                font-size: 18px;
            }
            @include media-breakpoint-up(lg){
                font-size: 20px;
            }
            @include media-breakpoint-up(xl){
                font-size: 22px;
            }
        }
    }
}