// Фильтры

.filters {
    // margin-top: 1rem;
    // margin-left: 1.125em;
    margin-right: 0;
}

.filter {
    &__btn {
        margin-top: .5em;
    }
}

// .form.filter{

// }