
.eggs{
	// @extend %border-top;
	// padding-top: 1rem;
	padding-bottom: 3rem;
	// background-color: $color-gray-1;
	&__item{
	}
	&__name{
		padding-left: 0;
		font-size: 2.5em;
		@include media-breakpoint-up(lg){
			font-size: 3em;
		}
	}
}

// .egg{
//     padding-top: 3rem;
//     padding-bottom: 3rem;
//     flex-direction: column;
// 	align-items: center;

// 	&__icon{
// 		font-size: 4rem;
// 		i{
// 			color: $color-prime;
// 		}
// 		padding-bottom: 30px;
// 	}
// 	&__name{
// 		font-size: 1rem;
// 		text-align: center;
// 		padding-bottom: 10px;
// 	}
// 	&__number{
//         font-family: $RobotoBold;
//         line-height: 100%;
//         font-size: 4rem;
//         color: $color-gray-4;
// 		text-align: center;
// 	}
// 	&__desc{
//         padding-top: 0.5rem;
//         font-family: $RobotoBold;
//         line-height: 100%;
//         font-size: 1rem;
//         color: $color-gray-4;
// 		text-align: center;
// 	}


// }