%menu-link-paddings{
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.menu{
    $root: &;
    margin-bottom: 0;

    &__link{
        @extend %flex;
        flex-direction: row;
        flex-wrap: nowrap;

        align-items: center;
        align-content: center;
        justify-items: center;
        vertical-align: middle;

        @extend %menu-link-paddings;

        text-align: center;
        line-height: 1.1rem;
        color: #fff;
        font-family: $ff-h-bold;
        text-decoration: none;
        word-wrap: break-word;
        word-break: keep-all;
        overflow-wrap: break-word;

        svg {
            padding-top: 2px;
            path {
                fill: $color-gray-4;
            }
        }

        @include hover-focus-active {
            color: #fff;
            background-color: #fff;
            svg path { fill: transparent }
        }

        &.active {
            color: $color-second;
            background-color: #fff;
            text-decoration: none;
            text-shadow: 0px 0px 4px #00000033;
        }
        
    }
    &__item, .popup__body__columns{
        margin-bottom: 0;
        .drop-menu {
            @extend .popup_hide;
        };
        @include hover-focus-active {
            >#{$root}__link{
                color: #fff;
                background-color: lighten($color-prime, 5%);
                svg path { fill: transparent }
            }
            >.drop-menu{
                @extend .popup_show;
            }
        }
        &.active{
            @include hover-focus-active {
                #{$root}__link{
                    color: $color-second;
                    background-color: #fff;
                    text-shadow: 0px 0px 4px #00000033;
                    svg path { fill: transparent }
                }
            }
        }
    }
}


.first-level{
    top: 49.6px;
    width: 360px;
    padding-top: 8px;

    background-color: $color-prime!important;

    &::after{
        content: '';
        position: absolute;
        top: 0;
        left: 30px;
        margin-right: 5px;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #fff;
    }
}

.other-levels{
    padding-top: 4px;
    left: 340px;
    width: 360px;

    background-color: $color-prime;
}

.active{
    .drop-menu{
        background-color: $color-second!important;
    }
}

.drop-menu{
    
    &__link{
        padding: 0.5rem 2rem 0.5rem 1rem;

        @extend %flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        align-content: center;

        font-family: $ff-h-bold;
        text-decoration: none;

        background-color: #fff;
        
        color: $color-gray-5;
        
        &__icon{
            height: 48px;
            margin-right: 1rem;
        }

        @include hover-focus-active{
            background-color: $color-gray-1;
            color: $color-second;
            // background-color: $color-gray-2;
            // color: #333;
        }
        &.active{
            color: $color-second;
        }
    }
    .popup{
        &__body{
            &__columns{
                padding-left: 0;
                padding-right: 0;
            }
        }

    }
}

// Mobile menu
.m-menu{
    top: 61px;
    right: 0;
    &__cover{
        position: relative;
        z-index: $z-m-menu__layout;
    }
    &__title{
        font-family: $ff-h-bold;
        @include media-breakpoint-up(lg){
            font-size: 16px;
        }
    }
    &__info {
        background-color: #fff;
        padding-top: 1.25em;
        padding-bottom: 1.125em;
        padding-left: 1.125em;
        border-bottom: 1px solid $color-gray-4;

        .header__contacts {
            padding-left: 1.125rem;
        }
    }
    &__phone{
        color: #333;
        padding-bottom: .375em;
        font-size: 1.375em;
        text-decoration: none;
    }
    &__draw{
        padding-top: .5em;
        padding-bottom: .75em;
        background-color: #fff;
    }
    &__capture{
        font-family: $ff-medium;
        font-size: 1.25em;
        color: $color-prime;
        
        background-color: #fff;
        border-top: 1px solid lighten($color-prime, 10%);

        padding-left: 1.125rem;
        padding-top: 1em;
        padding-bottom: .75em;
    }
    &__list{
        border-top: 2px solid $color-gray-4;
    }

    .menu__link{
        background-color: #fff;
        padding-left: 1.125rem;
        padding-top: .5em;
        padding-bottom: 0.5em;
        @include hover-focus-active{
            background-color: #FBFBFB;
        }
    }
}


.simple-catalog-menu{
    &__item{
        font-family: $ff-h-bold;
        line-height: 2em;
        text-decoration: none;
        @include hover-focus-active{
            text-decoration: underline;
        }
        span{
            line-height: inherit;
        }
    }
}
