.main-page{
    &__service{
        color: #fff;
        h2{
            color: #fff;
        }
        &__bg{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.75);
        }
    }
    
}
