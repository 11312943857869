.icons-cards{
    margin-top: 1.8em;
    margin-bottom: 2.3em;
}
// .icon-card__wrapper{
//     background-color: $color-bg-3;
// }
.icon-card{
    @include card;
    $root: &;
    
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 0;

    background-color: $color-bg-3;
    border: none;

    // font-family: $ff-h-bold;
    color: $color-base;
    text-decoration: none;

    &__icon{
        // width: 100px;
        // padding-right: 1rem;
    }

    &__name{
        padding-top: 1rem;
    }
} 