
.new-card {
    @include card;
    border-bottom: none;
    // padding-bottom: 1.5rem;
    background-color: #fff;

    &__name{
        a{
            font-size: 1.25rem;
        }
    }
    &__img{
        // max-height: 15rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    &__time{
        position: absolute;
        right: 0;
        padding: .3em .5em;

        font-family: $ff-bold;
        font-size: .875em;
        color: #fff;

        background-color: $color-gray-4;
    }
    &__anons{
        p{
            img{
                max-width: 100%;
            }
        }
    }
}

.news_list{
    .new-card{
        &__wrapper{
            padding-left: 0;
        }
    }
}


.news-short-list{
    list-style-type: none;
    padding-left: 2rem;
    &__item{

    }
}

.new-card_big {
    @include card;
    $root: &;
    border-bottom: none;

    @include hover-focus-active {
        box-shadow: none;
        cursor: default;
        border-bottom: none;

        #{$root}__name a {
            color: inherit;
            text-decoration: none;
        }
    }

    @include media-breakpoint-only(md) {
        margin-bottom: 1rem;
    }

    &__photo {
        padding-bottom: 0.5rem;

        @include media-breakpoint-up(md) {
            padding-bottom: 0;
        }
    }

    &__name {

        @include media-breakpoint-up(md) {
            padding-top: 0;
        }

        a {
            @extend .h2;

            text-decoration: none;
            line-height: 1.2em;

            @include hover-focus-active {
                color: $color-gray-4;
            }
        }
    }


}