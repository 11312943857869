%flex {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    flex: 1 0 auto;
}
%cont{
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    flex-direction: row;
    position: relative;
    width: 100%;
    padding: 0 $container-horizontal-padding;
    margin-right: auto;
    margin-left: auto;
    flex: 1 0 auto;
    @include media-breakpoint-only(lg){
        padding: 0 $container-horizontal-padding-lg;
    }
    @include media-breakpoint-only(xl){
        padding: 0 $container-horizontal-padding-xl;
    }
}
%col {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-left: $grid-gutter/2;
    padding-right: $grid-gutter/2;

    align-content: flex-start;
    align-items: flex-start;
}

.cover {
    // @extend %flex;
    @extend %cont;
    @include align(center, center, center);

    flex-direction: column;

    padding: 0;

    flex-grow: unset;
}
.cont{
    // @extend %flex;
    @extend %cont;
    @include align(flex-start, flex-start, flex-start);
    @include make-container-max-widths();
    flex-grow: unset;
}
.cont-fluid{
    // @extend %flex;
    @extend %cont;
    @include align(flex-start, flex-start, flex-start);
}

@include make-grid();


.companse, .compans{
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.pr-0 { padding-right: 0; }
.pl-0 { padding-left: 0; }