.article-card {
    @include card;
    &__wrapper{
        @include hover-focus-active {
            @extend %hover-effect-shadow;
        }
        margin-bottom: 2.5rem;
    }
    &__name{
        font-size: 1.3rem;
    }
    &__img{
        height: 15rem;
        margin-top: 1rem;
    }
    @include hover-focus-active {
        box-shadow: none;
    }
}