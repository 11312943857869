h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  @extend %flex;
  margin-top: $headings-margin-top;
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
  max-width: 100%;
  text-transform: none;

}

.landing{
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    @include media-breakpoint-up(md) {
      // padding-left: 1.5em;
    }
  }
  .gray-plate{
    .h1, .h2, .h3, h1, h2, h3{
      padding-left: 0;
    }
  }
}

h1, .h1 {
  font-family: $headings-font-family;
  margin-top: $h1-margin-top;
  margin-bottom: $h1-margin-bottom;
}
h1, .h1 { font-size: $h1-font-size-sm; }
h2, .h2 { font-size: $h2-font-size-sm; }
h3, .h3 { font-size: $h3-font-size-sm; }
h4, .h4 { font-size: $h4-font-size-sm; }
h5, .h5 { font-size: $h5-font-size-sm; }
h6, .h6 { font-size: $h6-font-size-sm; }

@include media-breakpoint-up(md) {
  h1, .h1 { font-size: $h1-font-size; }
  h2, .h2 { font-size: $h2-font-size; }
  h3, .h3 { font-size: $h3-font-size; }
  h4, .h4 { font-size: $h4-font-size; }
  h5, .h5 { font-size: $h5-font-size; }
  h6, .h6 { font-size: $h6-font-size; }
}


// @include media-breakpoint-up(md){
//   h1, .h1 { font-size: $h1-font-size-md; }
// }
// @include media-breakpoint-up(xl) {
//   h1, .h1 { font-size: $font-size-base * 2.125; }
//   h2, .h2 { font-size: $h2-font-size; }
// }

.lead {
  font-size: $lead-font-size;
  font-weight: $lead-font-weight;
}
.small{
  font-size: 0.8em;
}

p{
  line-height: $paragraph-line-height;
  margin-top: 0;
  margin-bottom: $paragraph-margin-bottom;
  width: 100%;
  font-size: 1rem;
}

ul{
  margin-top: 0;
  margin-bottom: $paragraph-margin-bottom;
  padding-left: 2em;
  li{
    margin-bottom: .4em;
    line-height: 1.4em;
  }
  &.done-list{
    li{
      
    }
  }
}


.list-simple{
  &__group{
    padding-bottom: 1rem;
  }
  &__title{
    
  }
}

strong{
  font-family: $ff-bold;
}
i{
  font-family: $ff-italic;
}

.subheader{
  text-align: inherit;

  margin-bottom: 1em;
  font-size: 1rem;
  line-height: 1.4em;
  font-family: $ff-normal;
  @include media-breakpoint-up(md) {
    font-size: 1.125rem;
  }
}
.title{
  font-family: $ff-bold;
}

.description{
  font-family: $ff-italic;
  padding-bottom: 1rem;
}
.table{
  .description{
    padding-bottom: 0;
  }
}
figure{
  @extend .img;
  figcaption{
    padding-top: 0.3rem;
    padding-bottom: 0.5rem;

    font-family: $ff-italic;
  }
}

.initial-letter{
  padding-left: 1.5rem;  
}
.antiinitial-letter {
  padding-left: 0px !important;
}

.content_text{
  // flex-direction: column;
  line-height: $paragraph-line-height;
  // font-size: 1.125em;

  img{
    max-width: 100%;
    height: auto;
  }
}

.footnote{
  font-family: $ff-italic;
  line-height: $paragraph-line-height;

  padding: .6em 0 .6em 1.3em;
  margin-top: 1em;
  margin-bottom: 1em;
  border-left: 5px solid $color-gray-3;
}

.text_error{
  font-family: $ff-normal;
  color: $color-danger;
}