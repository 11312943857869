a{
    color: $link-color;
    font-family: $link-font-family;
    text-decoration: $link-decoration;
    background-color: transparent; // Remove the gray background on active links in IE 10.
    -webkit-text-decoration-skip: objects; // Remove gaps in links underline in iOS 8+ and Safari 8+.
    @include hover {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
    }
}
.span-link{
    text-decoration: none;
    span {text-decoration: underline;}
    @include hover-focus {
        text-decoration: none;
        span {text-decoration: none;}
    }
}

.phone-link{
    color: inherit;
    text-decoration: none;
}