
@include media-breakpoint-only(xs){
    .fancybox-content{
        padding: 1rem!important;
    }
}
.about__video{
    iframe{
        width: 100%;
        height: auto;
        @include media-breakpoint-up(sm){
            height: 240px;
        }
        @include media-breakpoint-up(md){
            height: 320px;
        }
    }    
}

.history{
    // padding-top: 1rem;
    // padding-bottom: 1rem;
    &__item{
        border-left: 2px solid $color-gray-2;
        margin-bottom: $grid-gutter;
    }
    &__name{
        padding-left: 0;
    }
}


.partners{
    &__img{
        position: static;
        img{
            max-width: 100%;
            height: auto;
        }
    }
}

.backwords{
    margin-top: 0;
    margin-bottom: 0;
    padding-top: .25rem;
    padding-bottom: 1rem;
    // margin-bottom: -10px;
    font-size: 14px;
    line-height: 1.5em;
    z-index: 100;
    position: relative;

    &>div{
        margin-left: 15px;
    }
    &>div:first-child {
        margin-left: 0;
    }
}
.backwords-wrap .backwords {
    // margin-top: 20;
    // margin-bottom: 20px;
}

.fullscreen-bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: -100;
}

.fullscreen-bg__video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.sales-section{
    // padding-top: $grid-gutter;
    padding-bottom: 1rem;
    .cards{
        padding-top: 0;
    }
}

.search-result{
    @extend .content_text;
    position: relative;

    dt, dd{
        margin-left: 2rem;
    }
    dt a{
        font-family: $RobotoMedium;
        color: $font-color-base;
    }
    &__num{
        position: absolute;
        left: 0;
    }
}

.main-menu{
    height: 350px;
}

.navigate-icon{
    color: $color-gray-3;
}
.slider-icons{
    padding-top: 2rem;
    padding-bottom: 0rem;
}

.reklamnaya_vstavka {
    color: #fff;
    text-transform: uppercase;
    background-size: cover;
    .text1, .text2, .text3{
        text-align: center;
        line-height: 1.2em;
    }
    .text1, .text3{
        font-family: $ff-h-bold;
    }
    .text2{
        font-family: $ff-normal;
    }
}
.reklamnaya_vstavka{
    padding-top: 3em;
    padding-bottom: 3em;
    .text1 {
        font-size: 1.75em;
    }
    .text2 {
        font-size: 1.2em;
        margin-top: .2em;
        margin-bottom: .75em;
    }
    .text3{ font-size: 1.75em; }
}

@include media-breakpoint-up(lg){
    .reklamnaya_vstavka{
        height: 450px;
        .text1{
            font-size: 3em;
        }
        .text2{
            font-size: 2em;
            margin-top: .2em;
            margin-bottom: .75em;
        }
        .text3{
            font-size: 2.5em;
        }
    }
}

.upbtn{
    position: fixed;
    bottom: 3rem;
    right: 3rem;
    z-index: 1000;
    padding: 1rem 1.3rem;

    font-size: 1.5rem;
    color: #fff;
    background-color: $color-second;

    border-radius: 5px;
    &:hover{
        cursor: pointer;
    }
}

.demozone__photo{
    overflow: hidden;
    
}