.object{
    // padding-top: 1rem;
    padding-bottom: 2rem;
    &__photos{
        margin-bottom: 1.2rem;
        // order: 2;
        @include media-breakpoint-up(lg){
            // order: 1;
        }
        @include media-breakpoint-up(lg){
            margin-bottom: 0;
        }
    }
    &__main{
        // order: 1;
        padding-bottom: 2rem;
        @include media-breakpoint-up(lg){
            // order: 2;
        }
    }
    &__name{
        margin-top: 0.3em;
        // margin-bottom: 0.88888rem;
    }
    &__info{
        margin-top: 1rem;
        // padding-top: 2rem;
        // border-top: 2px solid $color-gray-3;
    }
    &__price-block{
        padding-top: 1.0rem;
        padding-bottom: 1.3rem;
    }
    &__price {
        // padding-top: 0.4em;
        // padding-bottom: 1.3em;
        font-size: 2em;
        line-height: 1em;
        font-family: $RobotoBold;
        color: $color-gray-6;
        .fas {
            font-size: 0.6em;
            color: $color-gray-4
        }
    }
    &__price-note{
        font-family: $ff-italic;
        color: $color-gray-4;

        // margin-bottom: 2em;
    }
    &__btn{
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    &__artikul{
        font-size: 16px;
        font-family: $RobotoMedium;

        span{
            font-family: $Roboto;
            color: $color-gray-4;
        }
    }
    &__action{
        padding-top: .4rem;
        padding-bottom: .6rem;
    }
}

.charact{
    line-height: $paragraph-line-height;

    &__dots{
        border-bottom: 1px dotted $color-gray-2;
        margin-bottom: 0.3em;
    }
}

.compatibility, .charact{
    margin-bottom: 1.5em;
}